@import url('https://fonts.googleapis.com/css?family=Assistant');

* {
  margin: 0;
  padding: 0;
  font-family: 'Assistant', serif ;
}
html {
  scroll-behavior: smooth;
}

