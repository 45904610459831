.center-carousel-main .react-multi-carousel-item:not(.react-multi-carousel-item--active) {
  opacity: 0.1;
}

.center-carousel-main .react-multi-carousel-item {
  justify-content: center;
  align-items: center;
  display: flex;
}

body{
  font-family: 'Assistant', serif ;
}

.pdf-preview-square {
  width: 100%;
  padding-top: 100%; /* Square aspect ratio */
  position: relative;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

//.pdf-thumbnail-image {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  object-fit: cover; /* Maintain aspect ratio */
//  object-position: top; /* Focus on the top of the document */
//}

.pdf-thumbnail-image:hover {
  transform: scale(1.05);
  transition: all 0.3s ease;
}

//.pdf-thumbnail-image {
//  width: 100%;
//  height: auto;
//  object-fit: cover;
//  border-bottom: 1px solid #e0e0e0;
//}

.square-thumbnail-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Aspect ratio of 1:1 for a square */
  overflow: hidden;
}

.pdf-thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top; /* Keeps focus at the top of the document */
}
